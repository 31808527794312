import "bootstrap-datetime-picker/js/bootstrap-datetimepicker.js";
import "bootstrap-datetime-picker/js/locales/bootstrap-datetimepicker.nl.js";

import "bootstrap-datetime-picker/css/bootstrap-datetimepicker.css";
// import '../stylesheets/datetimepicker.scss';

$(document).ready(function () {
  // TODO: we can merge functionality

  $(".datepicker").datetimepicker({
    autoclose: "true",
    format: "dd-mm-yyyy",
    minView: "2",
  });

  $(".datetimepicker_start")
    .datetimepicker({
      autoclose: "true",
      format: "dd-mm-yyyy hh:ii",
    })
    .on("changeDate", function (ev) {
      $(".datetimepicker_end").datetimepicker("setStartDate", ev.date);
    });

  $(".datetimepicker_end").datetimepicker({
    autoclose: "true",
    format: "dd-mm-yyyy hh:ii",
  });

  $(".birthdatepicker").datetimepicker({
    autoclose: "true",
    format: "dd-mm-yyyy",
    minView: "2",
  });

  $(".schedule_datetimepicker_start").datetimepicker({
    autoclose: "true",
    format: "dd-mm-yyyy",
    minView: "2",
  });

  $(".schedule_datetimepicker_end").datetimepicker({
    autoclose: "true",
    format: "dd-mm-yyyy",
    minView: "2",
  });

  $(".datetimepicker_vitals")
    .datetimepicker({
      autoclose: "true",
      format: "dd-mm-yyyy hh:ii",
    })
    .on("changeDate", function (ev) {
      $(".vitalmoment").val(ev.date);
    });
});
