$(document).ready(function() {

  $('.vitalinputblood').change(function() {
    var sys = $('.vit_blood_systolic').val();
    var dia = $('.vit_blood_diastolic').val();

    $('.vitals_bloodpressure').removeClass("btn-danger");
    $('.vitals_bloodpressure').removeClass("btn-success");

    if(sys.length > 0 && dia.length > 0)
      $('.vitals_bloodpressure').addClass("btn-warning");
    else
      $('.vitals_bloodpressure').removeClass("btn-warning");
  });


  $('.bloodvital_form').on('ajax:success', function(event) {
    var data = event.detail[0];
    $('.vit_blood_systolic').val("");
    $('.vit_blood_diastolic').val("");
    $('.vitals_bloodpressure').removeClass("btn-warning");
    $('.vitals_bloodpressure').addClass("btn-success");
    $('.vitals_bloodpressure_msg').html("Opgeslagen: " + data.systolic + " / " + data.diastolic);
  });

  $('#new_vitals_bloodpressure').on('ajax:error', function(res, status, xhr) {
    errors = res.detail[0];
    vital_error("bloodpressure", errors.value)
  });


  $('.vitalinput').change(function() {
    var vital = $(this).parent().parent().parent().data("vitname");
    vital_change(vital);
  });

  $('.vital_form').on('ajax:success', function(event) {
    var data = event.detail[0];
    var vital = $(this).data("vitname");
    vital_reset(vital, data.value);
  });

//  $('.vital_form').on('ajax:error', function(res, xhr, status, error) {
  $('.vital_form').on('ajax:error', function(res, status, xhr) {
    var vital = $(this).data("vitname");

    errors = res.detail[0];
    vital_error(vital, errors.value)
  });

});

function vital_change(vit) {
  $('.vitals_' + vit).addClass("btn-warning");
  $('.vitals_' + vit).removeClass("btn-danger");
  $('.vitals_' + vit).removeClass("btn-success");
}

function vital_reset(vit, value) {
  $('#vitals_' + vit + "_value").val("");
  $('.vitals_' + vit).removeClass("btn-warning");
  $('.vitals_' + vit).addClass("btn-success");
  $('.vitals_' + vit + "_msg").html("Opgeslagen: " + value);
}

function vital_error(vit, msg) {
  $('.vitals_' + vit + "_msg").html(msg);
  $('.vitals_' + vit).addClass("btn-danger");
  $('.vitals_' + vit).removeClass("btn-success");
  $('.vitals_' + vit).removeClass("btn-warning");
}
