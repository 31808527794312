import "fullcalendar/dist/fullcalendar.js";
import "fullcalendar/dist/locale/nl.js";
import "fullcalendar/dist/fullcalendar.css";

$(document).ready(function () {
  $("#calendar").fullCalendar({
    lang: "nl",
    defaultView: "basicDay",
    slotMinutes: 60,
    // aspectRatio: 1,
    header: {
      left: "",
      center: "",
      right: "today basicDay agendaWeek prev,next",
    },
    timeFormat: "H:mm: ",
    eventSources: [
      { url: "/manage/agenda_events/get_agenda_events" },
      { url: "/manage/bookings/start_events" },
      { url: "/manage/bookings/end_events" },
      //      {url: '/manage/patients/birthdays'}
    ],
    slotLabelFormat: "H:mm",

    views: {
      basic: {
        columnFormat: "dddd D MMMM",
      },
    },
  });

  $("#calendarm").fullCalendar({
    lang: "nl",
    defaultView: "basicDay",
    slotMinutes: 60,
    // aspectRatio: 1,
    header: {
      left: "",
      center: "",
      right: "",
    },
    timeFormat: "H:mm: ",
    eventSources: [
      { url: "/manage/agenda_events/get_agenda_events" },
      { url: "/manage/bookings/start_events" },
      { url: "/manage/bookings/end_events" },
      //      {url: '/manage/patients/birthdays'}
    ],
    slotLabelFormat: "H:mm",

    views: {
      basic: {
        columnFormat: "dddd D MMMM",
      },
    },
  });

  $("#planning_calendar").fullCalendar({
    defaultView: "agendaWeek",
    lang: "nl",
    header: {
      left: "title",
      center: "",
      right: "today agendaDay agendaWeek month prev,next",
    },
    weekNumbers: true,
    timeFormat: "H:mm: ",
    eventSources: [
      {
        url: "/manage/agenda_events/get_agenda_events",
      },
    ],
    slotLabelFormat: "H:mm",
    views: {
      day: {
        columnFormat: "dddd D MMMM",
      },
    },
  });

  $("#schedule_calendar").fullCalendar({
    defaultView: "agendaWeek",
    lang: "nl",
    header: {
      left: "title",
      center: "",
      right: "today agendaDay agendaWeek month prev,next",
    },
    // slotMinutes: 60,
    weekNumbers: true,
    timeFormat: "H:mm: ",
    eventSources: [
      {
        url: "/manage/schedules/get_schedules",
      },
    ],
    slotLabelFormat: "H:mm",
    views: {
      day: {
        columnFormat: "dddd D MMMM",
      },
    },
  });

  $("#capacity_calendar").fullCalendar({
    defaultView: "month",
    header: {
      left: "title",
      center: "",
      right: "today month prev,next",
    },
    weekNumbers: true,
    timeFormat: " ",
    eventSources: [
      {
        url: "/manage/bookings/get_capacity",
      },
    ],
    slotLabelFormat: "H:mm",
  });

  $("#overview_calendar").fullCalendar({
    defaultView: "month",
    header: {
      left: "title",
      center: "",
      right: "today agendaDay agendaWeek month prev,next",
    },
    allDayText: "Gehele dag",
    // slotMinutes: 60,
    weekNumbers: true,
    timeFormat: "H:mm: ",
    eventSources: [
      {
        url: "/manage/bookings/get_overview",
      },
    ],
    slotLabelFormat: "H:mm",
  });
});
