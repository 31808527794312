$(document).ready(function() {

  $('#invoice_action_action_id').on('change', function(e){
    var id = $(this).val();
    console.log(id);

    // if (id == 1 || id == 7)
    //   $("#invoice_form_action").attr("target","_blank");
    // else
    //   $("#invoice_form_action").attr("target","_self");

  });


  $('#invoices-ids').on('click', function(e) {
    var ids = new Array();

    jQuery(".table-active").each(function(){
      ids.push($(this).data("iid"));
    });

    jQuery("#invoice_action_ids").val(ids);

    var action = jQuery("#invoice_action_action_id option:selected").val();
    if (action.length == 0)
      return false;

    if (ids.length == 0){
      jQuery('#hintbox').removeClass("alert-light");
      jQuery('#hintbox').addClass("alert-warning");
      return false;
    }
  });


  if ($('#collapse-fields').length > 0) {
    $('.collapse').collapse();
  }

});
