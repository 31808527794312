import DataTable from "datatables.net-bs4";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";

new DataTable("#example");

$(document).ready(function () {
  console.log("dt doc ready");
  $(".datatable").DataTable({
    info: false,
    bPaginate: false,
  });

  $(".datatable-clean").DataTable({
    info: false,
    bPaginate: false,
    searching: false,
  });

  // when clicking on a tr toggle the checkbox
  $(".selectable tbody").on("click", "tr", function () {
    $(this).toggleClass("table-active");

    if ($(this).hasClass("table-active")) {
      var box = $(this).find("i.fa-square");
      box.addClass("fa-check-square");
      box.removeClass("fa-square");
    } else {
      var box = $(this).find("i.far");
      box.addClass("fa-square");
      box.removeClass("fa-check-square");
    }
  });

  $(".htmltip").tooltip({
    html: true,
  });

  $('[data-toggle="popover"]').popover();
});
