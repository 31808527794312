var idleTime = 0;

$(document).ready(function() {

  var idleInterval = setInterval(timerIncrement, 60000);
 //Zero the idle timer on mouse movement.
  $(this).mousemove(function (e) {
      idleTime = 0;
  });
  $(this).keypress(function (e) {
      idleTime = 0;
  });

  if ($(".monitor").length > 0) {
    console.log("mtimer loaded")
    setInterval(refreshMonitor, 60000);
  };


});

function timerIncrement() {
    idleTime = idleTime + 1;
    console.log("Idle for: " + idleTime);
    if (idleTime > 20) { // 21 minutes
        window.location.reload();
    }
}

function refreshMonitor() {
  console.log("reloading")
  window.location.reload();
}