
$(document).ready(function() {

  function mc_CareReport(rid) {

    this.dirty = false;
    this.rid = rid;

    this.changesNotSavedYet = function() {
      this.dirty = true;
      $("#save-msg").html("Aanpassingen zijn nog niet opgeslagen.");
      $("#publishreport").addClass("btn-warning");
      $("#publishreport").removeClass("btn-success");
    }

    this.saveDraft = function() {
      this.dirty = false;
      $("#save-msg").html("Opgeslagen");
      $("#publishreport").addClass("btn-success");
      $("#publishreport").removeClass("btn-warning");
    }

    this.showCategory = function() {
      var cid = $("#care_report_category_id option:selected").val();
      if (cid == 0)
        $(".care_report_careplan_id").show();
      else
        $(".care_report_careplan_id").hide();
    }
  }

  // toggle history on index
  $(".report").on("click", ".toggle-button", function() {
    var rid = $(this).data("rid");
    $(".report-" + rid).toggle();
  });

  if ($('#new_care_report, .edit_care_report').length) {
    report = new mc_CareReport( $("#care_report_id").val() );
    report.showCategory();
    setInterval(function() {
      if (report.dirty) {
        console.log("Saving report draft...");
        $("#draftreport").click();
      }
    }, 10000);
  }

  // toggle display of selection box for report category
  $('#care_report_category_id').change(function() {
    report.showCategory();
    report.changesNotSavedYet();
  });

  $('#care_report_careplan_id').change(function() {
    report.changesNotSavedYet();
  });

  // set id on successful draft save
  $('#new_care_report, .edit_care_report').on('ajax:success', function(event) {
    var data = event.detail[0];
    if (data.id != null) {
      $("#care_report_id").val(data.id);
      report.rid = data.id;

      var path = $("#publishreport").data("path");
      $("#publishreport").attr("href", path + data.id);

      var destroypath = $("#destroydraft").data("path");
      $("#destroydraft").attr("href", destroypath + data.id);

      report.saveDraft();
    }
  });

  $("#publishreport").click(function() {
    if (report.dirty == true) {
      alert("Aanpassingen zijn nog niet opgeslagen");
      return false;
    }

    // if we don't have an rid yet
    if (report.rid.length == 0)
      return false;
  });

  $("#destroydraft").click(function() {
    if (report.rid.length == 0) {
      alert("Er is nog geen rapportage opgeslagen om te kunnen verwijderen.");
      return false;
    }
  });

});
