import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/lists';
import 'tinymce-i18n/langs/nl';
import 'tinymce/skins/lightgray/content.min.css';
import 'tinymce/skins/lightgray/skin.min.css';

function tinyMce() {
    tinymce.init({
        selector: 'textarea.tinymce',
        plugins: ['lists'],
        branding: false,
        language: "nl",
        skin: false,
    });
}

function tinyMceReport() {
    tinymce.init({
        selector: 'textarea.report_content',
        plugins: ['lists'],
        branding: false,
        language: "nl",
        skin: false,
        height: 200,
        init_instance_callback: function (editor) {
          editor.on('Change', function (e) {
            // TODO: rework to event signal
            report.changesNotSavedYet();
          });
        }
    });
}

tinyMce();
tinyMceReport();
