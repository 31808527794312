/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import 'app';
import "../dashboard/jquery";
import "bootstrap/dist/js/bootstrap.js";

import Rails from "rails-ujs";
Rails.start();

import "../dashboard/datatables";
import "../dashboard/tinymce";
import "../dashboard/calendar";
import "../dashboard/datetimepicker";

import "../dashboard/timer";
import "../dashboard/invoices";

import "../dashboard/care/vitals";
import "../dashboard/care/reports";

import "@fortawesome/fontawesome-free/css/all.css";
